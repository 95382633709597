<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <the-nerve-table
    id="iiotNotificationsList"
    :columns="columns"
    :params="params"
    :empty-state="$t('notifications.list.empty')"
    :is-action-menu-enabled="false"
    :is-row-clickable="canAccess('UI_NOTIFICATION:VIEW')"
    :delete-message="$t('notifications.list.removeNotification')"
    store-module="notifications"
    data-cy="iiotNotificationsList"
    @row-clicked="(params) => editNotification(params.item)"
    @params-changed="(params) => changedParams(params)"
  >
    <template #additional-actions>
      <div>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              v-if="canAccess('UI_NOTIFICATION:CREATE')"
              id="iiotNotificationsNewButton"
              daya-cy="iiotNotificationsNewButton"
              fab
              small
              dark
              class="primary-tttech-background-btn mr-4"
              v-on="on"
              @click="addNewNotification()"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('notifications.list.addNotification') }}</span>
        </v-tooltip>
      </div>
    </template>
  </the-nerve-table>
</template>

<script>
import { TheNerveTable } from 'nerve-ui-components';

export default {
  components: {
    TheNerveTable,
  },
  props: {
    page: {
      type: Number,
      default: 0,
    },
    itemsPerPage: {
      type: Number,
      default: 0,
    },
    search: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeNotification: '',
      params: {},
    };
  },
  computed: {
    columns() {
      if (this.canAccess('UI_NOTIFICATION:DELETE')) {
        return [
          {
            text: this.$t('notifications.list.headerText'),
            value: 'headerText',
            sortable: false,
            isTruncateEnabled: true,
            width: '30%',
          },
          {
            text: this.$t('notifications.list.message'),
            value: 'textMessage',
            sortable: false,
            isTruncateEnabled: true,
            width: '30%',
          },
          {
            text: this.$t('notifications.list.active'),
            value: 'active',
            sortable: false,
            width: '40%',
            component: {
              sfc: () => import('@/components/notifications/ActiveColumn.vue'),
            },
          },
          {
            text: this.$t('defaultTable.action'),
            value: '',
            width: '0%',
            sortable: false,
            component: {
              sfc: () => import('@/components/notifications/NotificationListTableAction.vue'),
              props: {
                iconsAction: ['Delete'],
                fetchAction: () => this.filterBy,
              },
            },
          },
        ];
      }
      return [
        {
          text: this.$t('notifications.list.headerText'),
          value: 'headerText',
          sortable: false,
          isTruncateEnabled: true,
        },
        {
          text: this.$t('notifications.list.message'),
          value: 'textMessage',
          sortable: false,
          isTruncateEnabled: true,
        },
        {
          text: this.$t('notifications.list.active'),
          value: 'active',
          sortable: false,
          component: {
            sfc: () => import('@/components/notifications/ActiveColumn.vue'),
          },
        },
      ];
    },
    getNotifications() {
      return this.$store.getters['notifications/list'];
    },
  },
  async created() {
    this.getNotifications.forEach((notification) => {
      if (notification.active) {
        this.activeNotification = notification;
      }
    });
    this.params = {
      page: this.page || 1,
      itemsPerPage: this.itemsPerPage || 10,
      search: this.search || '',
    };
  },
  methods: {
    async filterBy() {
      try {
        await this.$store.dispatch('notifications/fetch', this.params);
      } catch (e) {
        this.$log.debug(e);
      }
    },
    changedParams(params) {
      this.params = params;
      this.$router
        .push({
          name: 'Notifications',
          query: {
            page: params.page,
            itemsPerPage: params.itemsPerPage,
            search: params.search || undefined,
          },
        })
        .catch(() => {});
    },
    editNotification(item) {
      this.$router.push({ name: 'Add edit notification', params: { id: item._id } });
    },
    addNewNotification() {
      this.$router.push({ name: 'Add edit notification', params: { id: 'new' } });
    },
  },
};
</script>
